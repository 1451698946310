import * as React from "react";
import translations from "./translations";

const langs = ["en", "tr"];

export const Translation = ({ t, lang, params }) => {
  const result = translate(t, lang, params);

  return <span dangerouslySetInnerHTML={{ __html: result }}></span>;
};

export const translate = (key, lang, params) => {
  const index = langs.indexOf(lang);

  if (index < 0) {
    console.error(`Language ${lang} is missing!`);
    return;
  }

  const t = translations[key];
  if (!t) return `[Missing Translation: ${key}]`;

  var result = t[lang];

  if(params) {
    Object.keys(params).forEach(x => {
      result = result.replace(`[${x}]`, params[x]);
    });
  }

  return result;
};
