import React from "react";

export const Step = (x) => {
  return (
    <div
      className={`step ${x.visited && "step-visited"} ${x.selected && "step-active"
        }`}
      style={{ borderColor: x.color }}
      onClick={x.onSelected}
    >
      {x.title}
    </div>
  );
};
