import React from "react";
import { inject, observer } from "mobx-react";
import { convertCurrencySummaryLocation, datePicker } from "../helpers";
import { Translation } from "../Translation";
import StarGreenIcon from "../icon/star_green.svg";

@inject("AppStore")
@observer
class PdfStep extends React.Component {
  store = this.props.AppStore;
  dates = datePicker(this.store.data.flat_name);

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content" id="pdf-step">
        <div className="left">
          <h2>ENTER YOUR DETAILS</h2>

          <form id="pdf_form" onSubmit={store.onPdfSubmit}>
            <div className="form">
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_first_name" />
                </label>
                <input
                  required
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  value={store.detailsForm.firstname}
                  onChange={(e) =>
                    (store.detailsForm.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_last_name" />
                </label>
                <input
                  required
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  value={store.detailsForm.lastname}
                  onChange={(e) =>
                    (store.detailsForm.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_email" />
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={store.detailsForm.email}
                  onChange={(e) => (store.detailsForm.email = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_phone" />
                </label>
                <input
                  required
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={store.detailsForm.phone}
                  onChange={(e) => (store.detailsForm.phone = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_boating" />
                </label>
                <input
                  type="text"
                  name="boating"
                  placeholder="Where are you boating?"
                  value={store.detailsForm.boating}
                  onChange={(e) => (store.detailsForm.boating = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={store.detailsForm.address}
                  onChange={(e) => (store.detailsForm.address = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={store.detailsForm.city}
                  onChange={(e) => (store.detailsForm.city = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={store.detailsForm.country}
                  onChange={(e) => (store.detailsForm.country = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Zip Code</label>
                <input
                  type="text"
                  name="zipcode"
                  placeholder="Zip Code"
                  value={store.detailsForm.zipcode}
                  onChange={(e) => (store.detailsForm.zipcode = e.target.value)}
                />
              </div>
            </div>

            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                fontSize: 12,
                lineHeight: 1.4,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              By submitting your email, you are opting in to receive emails from{" "}
              {this.store.location.company_name}
            </div>

            <div style={{ textAlign: "center", marginTop: 20 }}>
              {/* <div className="modelSelector__button">
                <a type="submit" onClick={() => { document.querySelector("#pdf_form").submit()}}>
                  <Translation t="button_pdf" lang={store.page.lang} />
                </a>
              </div> */}

              <button type="submit" className="button__clean">
                SEND PDF
              </button>
            </div>
          </form>
        </div>
        <div className="options-wrapper">
          {store.summary.model.selected_picture && (
            <img
              className="image"
              src={`${store.summary.model.selected_picture}`}
              alt=""
            />
          )}
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div className="selectedModel__subtitle">ORDER SUMMARY</div>
            </div>
            <div className="upgrades">
              {Object.keys(store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== "null" && (
                            <div className="upgradeoption-option-group-title">
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            store.summary.upgradeOptionsDisplay[step][
                            option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div className="upgradeoption-option-group">
                                {option_group}
                              </div>

                              {store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                >
                                  <span className="text">
                                    <div className="upgradeoption-option">
                                      <img
                                        src={StarGreenIcon}
                                        style={{
                                          width: 12,
                                          height: 12,
                                          marginRight: 5,
                                        }}
                                      />
                                      <span>{option.title}</span>
                                    </div>
                                  </span>
                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price,
                  this.store.location
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfStep;
