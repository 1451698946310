import * as React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "./config";
import { Loading } from "./Components/Loading";
import Axios from "axios";

@inject("AppStore")
@observer
class Broker extends React.Component {
  store = this.props.AppStore;
  location = "";

  state = {
    username: "",
    password: "",
    error: "",
  };

  componentDidMount() {
    const { location } = this.props.match.params;
    this.location = location;

    if (!location || location === "undefined") {
      window.location = "/";
      return;
    }

    this.store.getLocation(location);
    this.store.loadConfig();
    this.store.getModels();
  }

  onLogin = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(appConfig.locale.api + "/auth/local", {
        identifier: this.state.username,
        password: this.state.password,
      });

      localStorage.setItem("configurator:user", JSON.stringify(data));

      window.location.href = "/" + data.user.location.name;
    } catch (e) {
      this.setState({ error: "Invalid username / password!" });
    }
  };

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a href={`${appConfig.locale.url}`}>
              <img
                alt="logo"
                src={appConfig.locale.api + this.store.configuration.logo.url}
                className="logo"
              />
            </a>
          </div>
        </div>
        <div className="login">
          <div className="login-wrapper">
            <div className="login-header">Login</div>
            <div className="login-content">
              <form onSubmit={this.onLogin}>
                <div className="login-form">
                  <div className="login-form-item">
                    <input
                      required
                      type="text"
                      placeholder="Username"
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                    />
                  </div>
                  <div className="login-form-item">
                    <input
                      required
                      type="password"
                      placeholder="Password"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                  <div className="login-form-item">
                    <button style={{ cursor: "pointer" }}>LOGIN</button>
                  </div>
                  <div className="login-form-item">
                    <div className="login-form-error">{this.state.error}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Broker;
