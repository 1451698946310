import React from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { appConfig } from "../config";
import { convertCurrencyLocation } from "../helpers";

@inject("AppStore")
@observer
class CompleteStep extends React.Component {
  render() {
    const { store } = this.props;

    return (
      <div className="step-complete-content">
        <div
          className="image"
          style={{
            display: "none",
            backgroundImage:
              store.summary.model.selected_picture &&
              `url(${store.summary.model.selected_picture})`,
          }}
        ></div>
        <div className="complete-content">
          {store.page.completionType == "payment" ? (
            <div>
              <h2>
                YOUR{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: store.data.name }}
                ></span>{" "}
                ORDER IS READY
              </h2>

              <div>
                <p>
                  Your{" "}
                  {convertCurrencyLocation(
                    store.data.due_today_price[store.location.currency],
                    store.location
                  )}{" "}
                  down payment has been successfully received.
                </p>
                <p>Our team will contact you as soon as possible.</p>
              </div>

              <div>
                <strong>Model Name: </strong>
                <span
                  dangerouslySetInnerHTML={{ __html: store.data.name }}
                ></span>
                .
              </div>
              <div>
                <strong>Configuration Number:</strong> {store.page.order_number}
              </div>
              <div>
                <strong>Receipt:</strong>{" "}
                <a href={store.receipt_url} target="_blank">
                  Receipt Link
                </a>
              </div>
              <br />
              <div>
                The configuration you have prepared has been sent to your e-mail
                address. Please check your email address.
              </div>
              <br />
              <div>We will get in touch with you in a short while.</div>
              <br />
              <div>Thank you,</div>
              <br />
              <div>{store.location.company_name}</div>
              <br />
              <br />
              <div>
                {/* <img
               alt="logo"
               src={appConfig.locale.api + this.store.location.logo.url}
               className="logo"
             /> */}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Tel:</strong> {store.location.phone}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Email:</strong> {store.location.email}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Web:</strong>{" "}
                <a href={store.location.url}>{store.location.url_text}</a>
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Address:</strong> {store.location.address}
              </div>
            </div>
          ) : (
            <div>
              <h2>
                YOUR{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: store.data.name }}
                ></span>{" "}
                CONFIGURATION IS READY
              </h2>

              <div>
                <strong>Model Name: </strong>
                <span
                  dangerouslySetInnerHTML={{ __html: store.data.name }}
                ></span>
                .
              </div>
              <div>
                <strong>Configuration Number:</strong> {store.page.order_number}
              </div>
              <br />
              <div>
                The configuration you have prepared has been sent to your e-mail
                address. Please check your email address.
              </div>
              <br />
              <div>We will get in touch with you in a short while.</div>
              <br />
              <div>Thank you,</div>
              <br />
              <div>{store.location.company_name}</div>
              <br />
              <br />
              <div>
                {/* <img
                alt="logo"
                src={appConfig.locale.api + this.store.location.logo.url}
                className="logo"
              /> */}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Tel:</strong> {store.location.phone}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Email:</strong> {store.location.email}
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Web:</strong>{" "}
                <a href={store.location.url}>{store.location.url_text}</a>
              </div>
              <div style={{ marginBottom: 5 }}>
                <strong>Address:</strong> {store.location.address}
              </div>
            </div>
          )}

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <div
              className="modelSelector__cleanbutton"
              style={{ width: 230, textAlign: "center" }}
            >
              <a href={`${appConfig.locale.url}/build/${store.data.slug}`}>
                BACK TO HOME PAGE
              </a>
            </div>

            <div
              className="modelSelector__cleanbutton"
              style={{ width: 230, textAlign: "center", marginLeft: 50 }}
            >
              <a href={store.location.url}>{store.location.url_text}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompleteStep;
