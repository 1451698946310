import { inject, observer } from "mobx-react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { appConfig } from "../config";
import "./DependantPopup.css";

@inject("AppStore")
@observer
class DependantPopup extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {}

  onClose = (e) => {
    e.stopPropagation();
    this.store.popupCloseDependant();
  };

  render() {
    console.log(this.store.dependantsPopup.visible);
    if (!this.store.dependantsPopup.visible) return <React.Fragment />;

    return (
      <div className="dependants" onClick={this.onClose}>
        <div
          className="dependants__wrapper animate__animated animate__zoomIn"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="dependants__header">
            <div className="dependants__title">Warning!</div>
            <div className="dependants__close" onClick={this.onClose}>
              <AiOutlineClose fontSize="32" />
            </div>
          </div>
          <div className="dependants__content">
            <div className="dependants__dependant">
              <div className="dependants__option">
                {this.store.dependantsPopup.dependant.picture && (
                  <div
                    className="dependants__option--image"
                    style={{ marginRight: 15 }}
                  >
                    <img
                      alt=""
                      src={`${appConfig.api}${this.store.dependantsPopup.dependant.picture.url}`}
                    />
                  </div>
                )}

                <div className="dependants__option--title" style={{ fontSize: 14 }}>
                  {this.store.dependantsPopup.dependant.title}
                </div>
              </div>

              <div className="dependants__info">
                If you remove this option, selected options below will be
                removed.
              </div>
            </div>

            <div className="dependants__dependencies">
              {this.store.dependantsPopup.dependencies.map((dependency, i) => (
                <div
                  className="dependants__option"
                  key={i}
                  onClick={() => this.store.selectDependency(dependency)}
                >
                  {dependency.picture && (
                    <div className="dependants__option--image">
                      <img
                        alt=""
                        src={`${appConfig.api}${dependency.picture.url}`}
                      />
                    </div>
                  )}

                  <div className="dependants__option--title">
                    {dependency.title}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="dependants__footer">
            <div className="modelSelector__cleanbutton">
              <a onClick={this.store.removeDependants}>Approve</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DependantPopup;
